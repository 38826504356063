#globalnav {
  background: rgba(0,0,0,0.8);
  height: 48px;
}

#globalnav div {
  text-align: center;
  justify-content: space-evenly;
  display: flex;
}

#globalnav .gn-link {
  display: block;
  text-decoration: none;
}

#globalnav .gn-link-text {
  font-size: 15px;
  line-height: 3.14286;
  font-weight: 400;
  letter-spacing: -.01em;
  color: #f5f5f7;
  position: relative;
  z-index: 1;
  padding: 0 10px;
  height: 44px;
  opacity: .8;
  background: no-repeat;
  text-decoration: none;
  white-space: nowrap;
  -webkit-transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  -webkit-tap-highlight-color: transparent;
  outline-offset: -7px;
}

#globalnav .gn-link-text:hover {
  opacity: 1;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
