.avatar {
    width: 300px;
    height: 300px;
}
.site-title {
    max-height: 64px;
    max-width: 100%;
    font-family: 'Roboto Mono', monospace;
    font-size: 43px;
}

.intro-text {
    font-weight: bold;
}

.intro-quote-text {
    font-style: italic;
}

.site-title {
    color: grey;
}

.home-paper {
    width: 100%;
    height: 80%;
    top: inherit;
    left: 0px;
}

.home-paper img {
    width: 100%;
    height: auto;
}

.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }